.img-viewer-gallery {
    height: 230px;
    width: 350px;
    object-fit: contain;
}

.image-icons-container {
    margin-left: 18rem;
    margin-top: 0.5rem;
    position: absolute;
    z-index: 100;
}

.image-icons {
    margin-right: 0.5rem;
}

.image-icons:hover {
    cursor: pointer;
}