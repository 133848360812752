@import url("https://fonts.cdnfonts.com/css/alice-2");

html,body  
{  
    overflow-x: hidden;  
}  
.parent {
    display: grid;
    grid-template-columns: repeat(2, 0.7fr) 2fr 1fr;
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 6px;
    grid-row-gap: 5px;
}

.div1 {
    grid-area: 5 / 1 / 6 / 2;
    background-image: url("../../Assets/Images/Galerie/DAN_0809inv.png");
    min-height: 10%;
}

.div2 {
    grid-area: 5 / 2 / 6 / 3;
    background-image: url("../../Assets/Images/Galerie/DAN_0809inv@2x.png");
    min-height: 10%;
}

.div3 {
    grid-area: 1 / 1 / 3 / 3;
    background-image: url("../../Assets/Images/Galerie/DAN_0809inv@2x.png");
    min-height: 10%;
}

.div4 {
    grid-area: 3 / 1 / 5 / 3;
    background-image: url("../../Assets/Images/Galerie/DAN_0809inv@2x.png");
    min-height: 10%;
}

.div5 {
    grid-area: 1 / 3 / 4 / 4;
    background-image: url("../../Assets/Images/Galerie/DAN_0809inv@2x.png");
    min-height: 10%;
}

.div6 {
    grid-area: 4 / 3 / 6 / 4;
    background-image: url("../../Assets/Images/Galerie/DAN_0809inv@2x.png");
    min-height: 10%;
}

.div7 {
    grid-area: 1 / 4 / 3 / 5;
    background-image: url("../../Assets/Images/Galerie/DAN_0809inv@2x.png");
    min-height: 10%;
}

.div8 {
    grid-area: 3 / 4 / 6 / 5;
    background-image: url("../../Assets/Images/Galerie/DAN_0809inv@2x.png");
    /*min-height: 10%;*/
}

.btn-selected {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    border-top: rgb(100, 74, 130) 10px solid;
    border-left: none;
    border-right: none;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-40)/var(--unnamed-line-spacing-53) var(--unnamed-font-family-playfair-display);
    letter-spacing: var(--unnamed-character-spacing-0);
    font: normal normal bold 40px/53px Playfair Display;
    border-bottom: none;
}

.btn-unselected {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-40)/var(--unnamed-line-spacing-53) var(--unnamed-font-family-playfair-display);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-644a82);
    text-align: center;
    font: normal normal bold 40px/53px Playfair Display;
    letter-spacing: 0px;
    color: #644A82;
    opacity: 0.5;
}

.link-accueil {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-32)/var(--unnamed-line-spacing-43) var(--unnamed-font-family-playfair-display);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-644a82);

    font: normal normal bold 28px Playfair Display;
    letter-spacing: 0px;
    color: #644A82;
    opacity: 1;
}


@media (min-width : 321px) and (max-width : 600px) {
    .hide-img {
        display: none;
    }

    .text-header {
        font-size: 15px !important;
    }

    .carousel-home {
        width: 250px !important;
    }

    .btn-selected {
        font-size: 15px !important;
        padding: 0px !important;
    }

    .btn-unselected {
        font-size: 15px !important;
        padding: 0px !important;
    }

    .text_haut {
        font-size: 20px !important;
        width: 70%;
        
    }
}




/*
.haut_de_page {
}
*/

.header-container {
    text-align: center;
    display: flex;
}

.header {
    height: 500px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-image:
        linear-gradient(to bottom, rgba(100, 74, 130, 1), rgba(100, 74, 130, 0), rgba(100, 74, 130, 1)), url("../../Assets/Images/carousel/carousel3.jpeg");
        
    
}

.title-home {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-60)/var(--unnamed-line-spacing-80) var(--unnamed-font-family-playfair-display);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-644a82);
    text-align: center;
    font: normal normal bold 60px/80px Playfair Display;
    letter-spacing: 0px;
    color: #644A82;
    opacity: 1;
}

.card_cours {
    background-image: url("../../Assets/Images/Galerie/DAN_0809inv@2x.png");
    height: 400px;
}

.opacity-full {
    opacity: 1;
}


.photo_haut_de_page {
    background-size: cover;
    background-position: center;
    background-image: linear-gradient(to bottom, rgba(100, 74, 130, 1), rgba(100, 74, 130, 0), rgba(100, 74, 130, 1)), 
                      url("../../Assets/Images/carousel/carousel3.jpeg");
}



.card-cours-title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-32)/var(--unnamed-line-spacing-43) var(--unnamed-font-family-playfair-display) !important;
    letter-spacing: var(--unnamed-character-spacing-0) !important;
    color: var(--unnamed-color-ffffff) !important;
    text-align: left !important;
    font: normal normal bold 32px/43px Playfair Display;
    letter-spacing: 0px;
    color: #FFFFFF;
    position: absolute;
    top: 0px;
    left: 15px;
}

.logo_haut_de_page {
    width: 25rem;
    height: 25rem;
    position: absolute;
    left: 15%;
    top: -55%;
    background-color: transparent;
}

.rosas-container {
    margin-top: 5%;
    margin-left: 40%;
    vertical-align: middle;
    position: relative;
    display: inline-block;
}
@media (max-width:800px) {
    .rosas-container {
        margin-left: 0%;
    }
}
.rosas {
    width: 70%;
    opacity: 0.55; 

}

.hidden-data {
    visibility: hidden;
}

.link-card {
    position: absolute;
    bottom: 0;
    right: 20px;
    font-size: 40px;
}

.background-data {
    background-color: rgba(100, 74, 130, 0.7);
}

.none-displayed-card {
    display: none;
}

.pb-7 {
    padding-bottom: 100px;
}

.wave {
    width: 100%;
    background-color: linear-gradient(to bottom, rgba(100, 74, 130, 1), rgba(100, 74, 130, 0), rgba(100, 74, 130, 1));
}
@media (max-width:800px) {
    .wave {
        visibility: hidden;
    }
}

.reverse-wave {
    transform : rotate(180deg);
    
}

.wave-container {
    margin-bottom: 6%;
}

.txt-bouton {
    color: #644A82;
    padding: 15px 32px;
    text-align: center;
}

.border-cours {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 5px solid var(--unnamed-color-644a82);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 5px solid #644A82;
    border-radius: 46px;
    opacity: 1;
}

.border-cours-yellow {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 5px solid var(--unnamed-color-644a82);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 5px solid #f7b801;
    border-radius: 46px;
    opacity: 1;
}
.border-cours-red {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 5px solid var(--unnamed-color-644a82);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 5px solid #fb3640;
    border-radius: 46px;
    opacity: 1;
}
.text_haut {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    font-size: 25px;
    color: black;
    width: 60%;
    display: flex;
    margin-top: 120px;
    font-family: "Alice", sans-serif;
    font-size: 26px;
    /* margin-left: 165px; */
}

@media (max-width:800px) {
    .text_haut {
        margin-top: 100px;
        font-size: 22px;
    }
}

@media (max-width:800px) {
    .text_bas {
        margin-bottom: 20px;
    }
}

.text_bas {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Alice", sans-serif;
}

.button_danser {
    position: absolute;
    top: 62%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* border-radius: 7%;
    transform: translateY(700px);
    transform: translateX(300px); */
    border-radius: 10px 10px 10px 10px;
    border: #644a82;
    font-family: "Alice", sans-serif;
    height: 60px;
    width: 42%;
    background-color: #644a82;
    color: white;
    opacity: 1 !important;
    z-index: 1;
    
}

.button_fitness {
    /* border-radius: 7%;
    transform: translateY(700px);
    transform: translateX(300px);
  */
  position: absolute;
    top: 82%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 13px;
    border-radius: 10px 10px 10px 10px;
    height: 60px;
    font-family: "Alice", sans-serif;
    background-color: #f7b801;
    border: #f7b801;
    color: white;
    margin-bottom: 10px;
    opacity: 1 !important;
    z-index: 1;
    width: 42%;
}

.vague_sous_photo {
    background-color: transparent;
    display: flex;
    width: 70px;
    height: 97px;
}

.rotation {
    margin-bottom: -10px;
    transform: rotate(180deg);
    z-index: 1;
}

.redirection {
    margin-top: 20px;
    font-size: 20px;
    color: #644a82;
}

.redirection p {
    margin-right: 20px;
}

.bg-violet {
    background-color: rgb(100, 74, 130);
    padding-left: 1%;
}

.titre-planning {
    font-size: 60px;
    font-weight: bold;
}

.check-gonzague{
    width: 100%;
}
.nathounet-leadtech{
    display: flex;
    justify-content: end;
}