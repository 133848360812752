.horaire-planning {
  color: #644a82;
  font-size: 32px;
  text-align: center !important;
  font-weight: bold;
}

.sous-img {
  color: #644a82;
  font-size: 24px;
  text-align: center !important;
}

.sous-text {
  text-align: center;
  font-size: 18px;
  font-family: "Alice", sans-serif;
  max-width: 192px;
  overflow-wrap: break-word;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: -15px;
}

.bg-blanc {
  background-color: #ffffff;
  border-radius: 20px;
  max-width: 192px;
}



.spacingCard{
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.spacingCol{
  padding-left: 1% !important;
  padding-right: 1% !important;
}