@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');

.aSidebar {
  position: sticky;
  left: 0;
  top:0;
  width: 20%;
  min-height: 100vh;
  background-color: #644a82;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: darkgrey;
  box-shadow: 0px 0px 5px gray;
}

.aSidebar_logo {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  text-align: right;
}

.aSidebar_onglet {
  position: relative;
  display: inline-block;
  width: 100%;
  background-color: #644a82;
  margin-bottom: 5px;
  color: white;
  text-decoration: none;
  text-align: right;
}
.aSidebar_onglet:hover {
  background-color: white;
  color: #644a82;
  text-decoration: none;

  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: darkgrey;
}

.aSidebar_text {
  font-family: 'Playfair Display';
  position: relative;
  margin: 0;
  padding-right: 5%;
  vertical-align: top;
}

.currentPage {
  background-color: white !important;
  color: #644a82 !important;

  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: darkgrey;
}

.LogoBlanc{
  padding-top: 5%;
  padding-right: 10%;
}