.titre-actualite-admin {
    font-family: "Playfair Display";
    color: #644A82 !important;
    font: "Alice";
    font-size: 60px;
    font-weight: bold;
    margin-left: 3rem;
}

.upload-icon {
    transform: rotate(180deg);
}

.img-preview {
    margin-left: "2rem"; 
    object-fit: "contain"; 
    max-height: "150px"; 
    max-width: "300px";
}

.img-preview:hover {
    cursor: pointer;
}

.dropzone {
    width: 290px;
    height: 150px; 
}

.button-add {
    display: flex;
    background-color: hsl(357, 96%, 60%) !important;
    margin-left: 60rem;
    margin-bottom: 2.5rem;
    width: 300px;
    height: 50px;
    border-radius: 40px;
    text-align: center;
    font-size: 30px;
    font-family: "Alice";
}

.button-add:hover {
    background-color: hsl(357, 100%, 64%) !important;    
}

.label-button {
    margin-left: 1%;
}


.row-v2 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
@media screen and (min-device-width : 320px) and (max-device-width : 550px) {
    .row-v2{
        margin-left : 0 !important;
        margin-right : 0 !important;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

.actualite-page{
    margin:50px;
}

@media screen and (min-device-width : 320px) and (max-device-width : 550px) {
    .actualite-page{
        margin:0px;
    }
}