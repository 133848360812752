.navbar-brand {
    margin-left: 8%;
    margin-right: 25%;
    color: #FFFFFF !important;
    font-family: Vendetta;
    font-size: 14px;
}

.nav-item {
    font-family: PlayfairDisplay;
}

.nav-link {
    color: #ffffff !important;
}

.nav-link:hover {
    color: #ffffff !important;
}
.nav-redirect {
    color: #ffffff !important;
}

.nav-redirect:hover {
    color: #ffffff !important;
}


.navbar-toggler {
    color: #ffffff !important;
}

.navbar {
    background-color: #644A82;
}

@font-face {
    font-family: Vendetta;
    src: url("../../Assets/Fonts/Vendetta.otf");
}

@font-face {
    font-family: PlayfairDisplay;
    src: url("../../Assets/Fonts/PlayfairDisplay-Regular.ttf");
}

@media (max-width:800px) {
    .leaflet-container2{
        height: 150px !important;
        padding-top: 20%;
    }
}

a:hover{
    text-decoration: none;
}

.navicon{
    display: flex;
    justify-content: center;
}
.navbar-nav2{
    display: flex;
    list-style: none;
    text-align: center;
    justify-content: center;
}