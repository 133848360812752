@import url('https://fonts.googleapis.com/css2?family=Alice&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');

/* ********************************** */
/* La carte dans son ensemble */

.lArticle{
    width: 496px;
    height: 366px;
    background-color: #F2D7EE;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    box-shadow: 0px 3px 6px #00000029;
}

/* L'image de la carte (la première partie) */
.lArticle_img{
    position: relative;
    top:0;
    width: 100%;
    height: 50%;
}

/* L'article de la carte (la deuxième partei) */
.lArticle_txt{
    position: relative;
    top: 0%;
    width: 99%; /*width: 100%;*/
    height: 49%; /*height: 50%;*/
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
    margin-left: 0.5%;
    margin-top: 0.5%;
  
}

/* ********************************** */

/* Le titre de l'article */
.lArticle_label{
    position: relative;
    width: 90%;
    float: left;
    left: 5%;

    font-family: "Alice", sans-serif;
    text-align: left;
    color: #644A82;
    font-size: 18px;

    margin: 4% 0 2% 0;
}

/* Le résumer de l'article */
.lArticle_content{
    position: relative;
    width: 90%;
    float: left;
    left: 5%;

    font-family: "Alice", sans-serif;
    text-align: left;
    color:#444;
    font-size: 18px;
    line-height: 18px;

    margin: 0%;
}

/* La date de l'article */
.lArticle_date{
    position: absolute;
    width: 90%;
    float : right;
    right: 5%;
    bottom: 0;

    font-family: "Alice", sans-serif;
    text-align: right;
    color: #644A82;
    font-size: 18px;

    margin: 0%;
}

.lArticle_title{
    font-family: "Playfair Display", sans-serif;
    color: #644A82;
    font-size: 40px;
}