.red-bg {
  background-color: #fb3640;
}

.p-cours-titre {
  font-size: 32px;
  font-family: "Playfair Display", Bold;
  text-align: left;
}

.box-footer-cour {
  font-family: "Alice";
  color: #ffffff;
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  margin-bottom: 0px;
}

.footer-button-cours {
  font-family: "Playfair Display";
  color: #fb3640;
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: bold;
}

.margin-btt {
  margin: 20px 47px 20px 47px;
}
