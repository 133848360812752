.body {  
  background: rgb(0, 0, 0);
  height: 916px;
}

.titre-galerie {
  font-size: 60px;
  height: 9rem;
  font-family: 'Playfair Display';
  text-align: center;
  display: flex;
  color: #644A82;
  font-weight: bold;
  background-color: white;
}

.text-galerie {
  margin:auto;
}

.img-galerie{
  
}
@media only screen and (max-width: 600px) {
  .img-galerie{
    width: 100%!important;
  }
  .m-bottom{
    margin-top: 40px;
  }
  .body{
    margin-bottom: 40px;
   
  }
  .main-carou{
    background-color: black;
  }
}
body {
  font-family: 'Playfair Display';
}

.carou{
    /* width: 180px; */
    border: 5px solid var(--unnamed-color-f2d7ee);
    border: 5px solid #F2D7EE;
    border-radius: 10px;
    opacity: 1;
    /* margin-top: -38%;
    margin-right: 15rem; */
    float: right;
}

.visualiseuse{
  float: left;
  border: 5px solid #F2D7EE;
}

.main-carou{
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 30px;
}

