.cardCours2 {
    color: white;
    background-color: #644A82;
    border-radius: 20px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cardCours2 .titreCours {
    text-align: left;
    font-size: 24px;
    padding: 20px 15px 0 15px;
    margin-bottom: 0;
    font-weight: bold;
}
