.footer {
    /* UI Properties */
    background: #000000;
    width: 100%;
    flex-wrap: wrap;
}

.footerH1 {
    color: #ffffff;
    margin-top: 15%;
    margin-bottom: 10%;
}

.text {
    color: #ffffff;
    display: block;
}

.socialIcon {
    margin-top: 7%;
    margin-bottom: 2%;
}

.leaflet-container2{
    height: 270px !important;
    width: 100%;
    z-index: 0;
}

.map {
    width: 100%;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
}
