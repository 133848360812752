@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

  /*CSS DU CARROUSEL VIOLET*/
  .mb-4{
    margin-bottom: 1.5rem!important;
  }
  .swiper-button-prev::after {
    content: '‹'; /* code unicode pour la flèche gauche */
    font-size: 100px;
    margin-left: 30px;
    top: 40%;
    /*position: absolute;*/
  }
  
  .swiper-button-next::after {
    content: '›'; /* code unicode pour la flèche droite */
    font-size: 200px;
    margin-right: 30px;
    top: 40%;
    /*position: absolute;*/
  }

  .swiper-container .swiper-button-prev::after {
    color: #644a82; /*Couleur flèche de gauche*/
  }

  .swiper-container .swiper-button-next::after {
    color: #644a82; /*Couleur flèche de droite*/
  }

  .swiper-slide {
    /*height: 200px;*/
    padding-top: 55px;
    /* padding-bottom: 10px; */
    /* padding-left: 100px; */
    /*padding-right: 150px;*/
    /*margin-right: 25px;*/
     /* height: 300px;
      line-height: 300px;
      text-align: center;*/
  }
  
  /* .slide-content {
    /*height: 100%;
    /*display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } */

  .swiper-container{
    border: 4px solid #644a82;
    border-radius: 30px;
    width: 100%;
    overflow: hidden;
    display: block;
    background-color: white;
    transform: translateY(-50px);
}

.background-purple{
  background-image: url("background.png"); 
  background-repeat: no-repeat;
  background-position-y: 189px;
}

.background-yellow{
  background-image: url("background_Yellow.png"); 
  background-repeat: no-repeat;
  background-position-y: 189px;
}
.background-red{
  background-image: url("background_Red.png"); 
  background-repeat: no-repeat;
  background-position-y: 189px;
}
.title-div{
  justify-content: center;
  align-items: center;
  display: flex;
}


.img-div-logo{
  margin-bottom: 209px;
}
.Carre{
  position:absolute;
  z-index:1;
  border-top: solid #644a82;
  border-width: 11px;
  color: #644a82;
  width: 40%;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 40px;
}
.Carre_Yellow{
  position:absolute;
  z-index:1;
  border-top: solid #f7b801;
  border-width: 11px;
  color: #f7b801;
  width: 40%;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 40px;
}
.Carre_Red{
  position:absolute;
  z-index:1;
  border-top: solid #fb3640;
  border-width: 11px;
  color: #fb3640;
  width: 40%;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 40px;
}
@media only screen and (max-width: 767px) {
  .Carre, .Carre_Yellow, .Carre_Red {
    width: 80%;
    font-size: 30px;
  }
}
.mainDiv{
  width: 100%;
  height: 250px;
}

.imgLogo{
  width: 19%;
  height: 10px;
  margin-left: 41.50%;
  transform: translateY(-55px);
  top: 10px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-image: url("./Groupe 46.png"); */
}

.caroussel_image{
    width: 300px;
    height: 400px;
    border-radius: 30px;
}

.logo_carrousel{
  height: 100px;
  transform: translateY(961px);
  margin-right: 30px;
  border-radius: 30px;
}
/*FIN DU CSS CARROUSEL VIOLET*/

/*------------------------------------------------------------------------------------*/

/*CSS DU CARROUSEL JAUNE*/

.background_Yellow{
  background-image: url("background_Yellow.png"); 
  background-repeat: no-repeat;
  background-position-y: 170%;
  background-position-x:20%;
  z-index: 0;
  position: relative;
  padding-bottom: 350px;
  transform: translateY(215px);
}

.swiper-container-Yellow{
  border: 4px solid #f7b801;
  border-radius: 30px;
  max-width: 85%;
  width: 100%;
  overflow: hidden;
  margin-bottom: 100;
  display: block;
  background-color: white;
  transform: translateY(240px);
}

.swiper-container-Yellow .swiper-button-prev::after {
  color: #f7b801; /*Couleur flèche de gauche*/
}

.swiper-container-Yellow .swiper-button-next::after {
  color: #f7b801; /*Couleur flèche de droite*/
}

.logo_carrousel_Yellow{
  height: 100px;
  transform: translateY(1810px);
  margin-right: 30px;
  border-radius: 30px;
}

.imgLogo_Yellow{
  width: 19%;
  height: 10px;
  margin-left: 41.50%;
  margin-top: 100px;
  top: 10px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
}

/*FIN DU CSS CARROUSEL JAUNE*/

/*------------------------------------------------------------------------------------*/

/*CSS DU CARROUSEL ROUGE*/

.background_Red{
  background-image: url("background_Red.png"); 
  background-repeat: no-repeat;
  background-position-y: 150%;
  background-position-x: 100%;
  height: 600px;
  z-index: 0;
  position: relative;
}

.swiper-container-Red{
  border: 4px solid #fb3640;
  border-radius: 30px;
  max-width: 85%;
  width: 100%;
  overflow: hidden;
  margin-bottom: 0px;
  display: block;
  background-color: white;
}

.swiper-container-Red .swiper-button-prev::after {
  color: #fb3640; /*Couleur flèche de gauche*/
}

.swiper-container-Red .swiper-button-next::after {
  color: #fb3640; /*Couleur flèche de droite*/
}


.logo_carrousel_Red{
  height: 80px;
  transform: translateY(2730px);
  margin-right: 30px;
  border-radius: 30px;
}

.imgLogo_Red{
  width: 19%;
  height: 10px;
  margin-left: 41.50%;
  margin-top: 100px;
  top: 10px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
}

/*FIN DU CSS CARROUSEL ROUGE*/


.carousel-div-main{
  padding: 20px;
}