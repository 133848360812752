.titre-news {
    font-family: "Playfair Display";
    color: #644A82 !important;
    font: "Alice";
    font-size: 60px;
    font-weight: bold;
}

.row-v2 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
@media screen and (min-device-width : 320px) and (max-device-width : 550px) {
    .row-v2{
        margin-left : 0 !important;
        margin-right : 0 !important;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

.actualite-page{
    margin:50px;
}