.card {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: transparent;
}

.groupe {
  display: flex;
  margin-bottom: 50px;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.cropedDiv {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
}

.grow {
  display: flex;
  justify-content: center;
  height: 100%;
  gap: 10px;
  overflow-x: hidden;
  height: auto;
}

.grow > div {
  flex-basis: 20%; /* Increase the width of the cards */
  flex-shrink: 1;
  transition: .5s;
  width: 100%; /* Increase the width of the cards */
}
@media (max-width:800px) {
  .grow > div {
    flex-basis: 31%; /* Increase the width of the cards */
    flex-shrink: 1;
    transition: .5s;
    width: 100%; /* Increase the width of the cards */
  }
}
.grow > div:hover {
  flex-basis: 25%; /* Increase the width of the cards on hover */
  max-width: 100%;
  height: auto;
  transition: .5s; /* Adjust the transition duration to match the .grow > div rule */
}


.grow>div:first-child:hover {
  margin-left: 2%;
}

.grow>div:last-child:hover {
  margin-right: 2%;
}

/* .textforfait{
   position: absolute;
   margin-top: 55px;
   margin-left: 610px;
   font: normal normal bold 36px/48px Playfair Display;
   letter-spacing: 0px;
   color: #FFFFFF;
   opacity: 1;
   transition-duration: 500ms;
 }
 
.textdanses{
    position: absolute;
    margin-top: 55px;
    margin-left: 80px;
    font: normal normal bold 36px/48px Playfair Display;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    transition-duration: 500ms;
}

.textfitness{
   position: absolute;
   margin-top: 55px;
   margin-left: 340px;
   font: normal normal bold 36px/48px Playfair Display;
   letter-spacing: 0px;
   color: #FFFFFF;
   opacity: 1;
   transition-duration: 500ms;
} */

.card-img-top {
  height: 400px;
  border-radius: 20px;
  object-fit: cover;
  transition-duration: 0.2s;
}


.card-img-top:hover {
  max-width: 100%;
  width: 100%;
  transition-duration: 0.2s;
}

.img__description {
  color: white;
  font-size: 26px;
  font-weight: bold;
  position: absolute;
  align-items: center;
  display: flex;
  justify-content: center;
}

.card-text {
  color: white;
  opacity: 1;
}

.titlecours {
  padding-top: 30px;
  color: #644A82;
  opacity: 1;
  letter-spacing: 0px;
  font: normal normal 60px/80px Playfair Display;

}

.vague {
  width: 100%;
  height: 100%;
}

.divvague {
  height: auto;
  width: auto;
  margin-bottom: 80px;
}

.conteneur {
  background-color: #F2D7EE;
  background-image: url("fond.svg");
  background-size: 80%;
  background-position: 150px -30px;
  height: 515px;
  background-repeat: no-repeat;
}

.conteneur {
  background-color: #F2D7EE;
  background-image: url("fond.svg");
  background-size: 80%;
  background-position: 150px -30px;
  height: 515px;
  background-repeat: no-repeat;
}

@media (max-width:800px) {
  .conteneur {
    background-color: #F2D7EE;
    background-image: url("fond.svg");
    background-size: 80%;
    background-position: 50px -30px;
    height: 515px;
    background-repeat: no-repeat;
  }
}